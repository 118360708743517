import React from 'react';
import { Link, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Layout from '../../components/fr';
import SEO from '../../components/seo';

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/blog/**/*.fr.md" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            path
            tags
            author_name
            author_id
            cover {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 400, maxHeight: 300, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

class Blog extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const { currentPage, numPages, tags } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage - 1 === 1
        ? '/fr/blog/'
        : `/fr/blog/${(currentPage - 1).toString()}`;
    const nextPage = `/fr/blog/${(currentPage + 1).toString()}`;

    return (
      <Layout data={data} location={typeof window !== 'undefined' ? location : null}>
        <SEO
          title="Blog - Furlan Snowboards"
          description="Bienvenue sur le Blog Furlan"
          keywords={['snowboard', 'boards', 'versatility']}
          pathname="/fr/blog"
        />
        <div className="blogPage">
          <div className="container">
            <div className="row">
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                  <div className="col-sm-6 col-md-4 p-1 pb-4 p-md-4" key={node.fields.slug}>
                    <Link to={node.frontmatter.path}>
                      <Img
                        fluid={node.frontmatter.cover.childImageSharp.fluid}
                        alt="Furlan Snowboards"
                      />
                      {node.frontmatter.tags &&
                        node.frontmatter.tags.map((tag) => {
                          const upperTag = tag.charAt(0).toUpperCase() + tag.slice(1);
                          return (
                            <Link key={tag} to={`/fr/tags/${tag}`}>
                              <span className="badge badge-pill badge-primary float-right">
                                {upperTag}
                              </span>
                            </Link>
                          );
                        })}
                    <h4 className="mt-3">{title}</h4>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          {/*
          <div className="container page-links">
            <div className="row">
              <nav aria-label="Blog navigation" className="col-lg-12 mx-auto">
                <ul className="pagination">
                  {!isFirst && (
                    <li className="page-item">
                      <Link to={prevPage} rel="prev" className="page-link">
                        ← <FormattedMessage id="previous" />
                      </Link>
                    </li>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      className="page-item"
                      style={{
                        margin: 0,
                      }}
                    >
                      <Link
                        to={`/fr/blog/${i === 0 ? '' : i + 1}`}
                        className="page-link"
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  {!isLast && (
                    <li className="page-item">
                      <Link to={nextPage} rel="next" className="page-link">
                        <FormattedMessage id="next" /> →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
                  </div> */}
        </div>
      </Layout>
    );
  }
}

export default Blog;
